import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css"
import "./scss/custom-theme.scss"
import NavBar from "./components/NavBar";
import Banner from "./components/Banner";
import Memberships from "./components/Memberships";
import Coaches from "./components/Coaches";
import Footer from "./components/Footer";
import Pt from "./components/Pt";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import OurStory from "./components/OurStory";
import NotFound from "./components/NotFound";
// import Merch from "./components/Merch";

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" exact element={<Banner />} />
          <Route path="/private-training" exact element={<Pt />} />
          {/* <Route path="/memberships" element={<Memberships />} /> */}
          {/* <Route path="/merch" element={<Merch />} /> */}
          <Route path="/coaches" element={<Coaches />} />
          <Route path="/our-story" element={< OurStory/>} />
          <Route path="*" element={< NotFound/>} />
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
