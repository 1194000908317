import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../assets/gloves-up-logo.png";

export default function NavBar() {
  const [activeLink, setActiveLink] = useState("home");
  const [smallMenu, setSmallMenu] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setSmallMenu(window.innerWidth < 992 || window.scrollY > 100);
    };

    const handleScroll = () => {
      setSmallMenu(window.innerWidth < 992 || window.scrollY > 100);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function updateActiveLink(value) {
    setActiveLink(value);
  }

  const handleClassesClick = (e) => {
    e.preventDefault();
    const isHomeRoute = window.location.pathname === "/";

    if (isHomeRoute) {
      const element = document.getElementById("classes-nav");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate("/", { replace: true });
      setTimeout(() => {
        const element = document.getElementById("classes-nav");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100); 
    }

    updateActiveLink("classes-nav");
  };

  return (
    <Navbar
      expand={smallMenu ? "xs" : "lg"}
      className={smallMenu ? "scrolled" : ""}
    >
      <Container>
        <Navbar.Brand
          href="/"
          className={smallMenu ? "nav-brand-small" : "nav-brand"}
        >
          <img src={logo} alt="Logo" className="nav-brand-small" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="nav-toggle">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse className="justify-content-end">
          <Nav className={smallMenu ? "navbar-toggle" : ""}>
            <Nav.Link
              href="#"
              className={smallMenu ? "navbar-link-toggle" : "navbar-link"}
              onClick={handleClassesClick}
            >
              Classes
            </Nav.Link>
            <Nav.Link
              href="/private-training"
              className={smallMenu ? "navbar-link-toggle" : "navbar-link"}
              onClick={() => updateActiveLink("private-training")}
            >
              Private Training
            </Nav.Link>
            {/* <Nav.Link
              href="/memberships"
              className={smallMenu ? "navbar-link-toggle" : "navbar-link"}
              onClick={() => updateActiveLink("memberships")}
            >
              Memberships
            </Nav.Link>
            <Nav.Link
              href="/merch"
              className={smallMenu ? "navbar-link-toggle" : "navbar-link"}
              onClick={() => updateActiveLink("merch")}
            >
              Merch
            </Nav.Link> */}
            <Nav.Link
              href="/coaches"
              className={smallMenu ? "navbar-link-toggle" : "navbar-link"}
              onClick={() => updateActiveLink("coaches")}
            >
              Coaches
            </Nav.Link>
            <Nav.Link
              href="/our-story"
              className={smallMenu ? "navbar-link-toggle" : "navbar-link"}
              onClick={() => updateActiveLink("our-story")}
            >
              Our Story
            </Nav.Link>
            {/* <Nav.Link
              href="/coaches"
              className={smallMenu ? "navbar-link-toggle" : "navbar-link"}
              onClick={() => updateActiveLink("story")}
            >
              Our Story
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
